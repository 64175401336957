// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import 'channels'
import 'bootstrap/js/src/collapse'  
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/scrollspy'
import 'jquery'
import 'bootstrap'

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

Rails.start()
ActiveStorage.start()